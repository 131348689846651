import {
  findCompRefButton,
  findDefaultPDFLink,
  getData,
  updateButtonRefData,
  updateDataAfterCloseSettings,
  updateFileNameBeforeOpenNativeSettings,
  updateTextRefData,
  updateUrlPDF,
} from './assets/helpers/helpersFunctions';
import {
  ComponentRef,
  ResponsiveLayout,
  WidgetInstallationType,
  WidgetPreset,
} from '@wix/platform-editor-sdk';
import {
  FlowAPI,
  FlowAppDefinitionId,
  FlowEditorSDK,
  FlowPlatformOptions,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import {
  IPanelsApi,
  PanelsApiFactory,
} from '@wix/blocks-widget-services/panels';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { designPresetsFullPage } from './assets/presets/designPresetsDesktop';
import { desktopPresets, mobilePresets } from './assets/presets/presetsId';
import { WidgetPresetsBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetPresetsBuilder';
import { WriteOnlyBuilder } from '@wix/app-manifest-builder/dist/types/types';
import { LIGHT_BOX, casePanel } from './assets/constants/variables';
import webBiLogger from '@wix/web-bi-logger';
import { pdfDetails } from '@wix/bi-logger-wixlabs-users/v2';

let panelsApi: IPanelsApi;
let myButtonRef: ComponentRef;
let myTextRef: ComponentRef;
export const getAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  contextParams,
  flowAPI: FlowAPI,
): Promise<AppManifest> => {
  const { t } = flowAPI.translations;

  const getButtonRefComponent = async (elementType, widgetRef?) => {
    let allComponents: any[] = [];
    if (widgetRef) {
      allComponents = await editorSDK.document.components.getChildren('token', {
        componentRef: widgetRef,
        recursive: true,
      });
    } else {
      allComponents = await editorSDK.document.components.getAllComponents(
        'token',
      );
    }
    let refComponent;
    for (const comp of allComponents) {
      const type = await editorSDK.document.components.getType('token', {
        componentRef: comp,
      });
      if (type === elementType) {
        refComponent = comp;
        break;
      }
    }
    return refComponent;
  };
  if (myButtonRef === undefined || myButtonRef === null) {
    myButtonRef = await getButtonRefComponent('wixui.StylableButton');
  }
  if (myTextRef === undefined || myTextRef === null) {
    myTextRef = await getButtonRefComponent(
      'wysiwyg.viewer.components.WRichText',
    );
  }
  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;

    switch (id) {
      case casePanel.MANAGE_PDF: {
        const oldData: any = await editorSDK.document.components.data.get(
          'token',
          {
            componentRef,
          },
        );

        const { props } = JSON.parse(oldData.settings);
        const oldUrl = props?.url;
        await panelsApi.openBlocksPanel('Manage PDF', componentRef, {
          overrides: {
            title: t('app.settings.upload.pdf'),
          },
        });
        const newData: any = await editorSDK.document.components.data.get(
          'token',
          {
            componentRef,
          },
        );

        const { props: newProps } = JSON.parse(newData.settings);
        const newUrl = newProps?.url;
        const newFileName = newProps?.fileName;

        if (newUrl !== oldUrl) {
          await updateTextRefData(editorSDK, myTextRef, newFileName);

          await updateButtonRefData(editorSDK, myButtonRef, newProps);
        }
        break;
      }
      case casePanel.MANAGE_SETTINGS: {
        myButtonRef = await getButtonRefComponent(
          'wixui.StylableButton',
          componentRef,
        );
        myTextRef = await getButtonRefComponent(
          'wysiwyg.viewer.components.WRichText',
          componentRef,
        );
        const multistate: any = await editorSDK.document.components.data.get(
          'token',
          {
            componentRef,
          },
        );

        const { props } = JSON.parse(multistate.settings);
        if (props?.embedMode === LIGHT_BOX) {
          const widgetProps =
            await editorSDK.application.appStudioWidgets.props.get('token', {
              widgetRef: componentRef,
            });
          const { fileName, showPrint, showDownload, startPage } = widgetProps;
          if (fileName === undefined) {
            await editorSDK.document.components.data.update('token', {
              componentRef: myButtonRef,
              data: {
                label: t('app.settings.gfpp.pdf.name'),
              },
            });
          } else {
            await editorSDK.document.components.data.update('token', {
              componentRef: myButtonRef,
              data: {
                label: fileName,
              },
            });
          }
          await editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
            'token',
            'settings',
            {
              componentRef: myButtonRef,
              configuration: {
                controls: {
                  link: { hidden: true },
                  label: {},
                  icon: {},
                },
                controlGroups: {},
                states: {},
              },
            },
          );
          const getDataButton: any = await getData(editorSDK, myButtonRef);

          await editorSDK.application.appStudioWidgets.props.set('token', {
            widgetRef: componentRef,
            newProps: {
              ...widgetProps,
              fileName: getDataButton.label,
              showPrint,
              showDownload,
              startPage,
            },
          });
          await updateTextRefData(editorSDK, myTextRef, getDataButton.label);
        } else {
          await panelsApi.openBlocksPanel('Settings', componentRef, {
            overrides: {
              title: t('app.settings.gfpp.settings'),
            },
          });
        }
        break;
      }
      case casePanel.MANAGE_LAYOUT: {
        myButtonRef = await getButtonRefComponent(
          'wixui.StylableButton',
          componentRef,
        );
        myTextRef = await getButtonRefComponent(
          'wysiwyg.viewer.components.WRichText',
          componentRef,
        );
        const multistate: any = await editorSDK.document.components.data.get(
          'token',
          {
            componentRef,
          },
        );
        const { props } = JSON.parse(multistate.settings);
        if (props?.embedMode === LIGHT_BOX) {
          await editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
            'token',
            'layout',
            {
              componentRef: myButtonRef,
            },
          );
        } else {
          await panelsApi.openBlocksPanel('LayoutPanel', componentRef, {
            overrides: {
              title: t('app.settings.gfpp.layout'),
            },
          });
        }
        break;
      }
      case casePanel.MANAGE_DESIGN: {
        myButtonRef = await getButtonRefComponent(
          'wixui.StylableButton',
          componentRef,
        );
        myTextRef = await getButtonRefComponent(
          'wysiwyg.viewer.components.WRichText',
          componentRef,
        );
        const multistate: any = await editorSDK.document.components.data.get(
          'token',
          {
            componentRef,
          },
        );
        const { props } = JSON.parse(multistate.settings);
        if (props?.embedMode === LIGHT_BOX) {
          await editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
            'token',
            'design',
            {
              componentRef: myButtonRef,
            },
          );
        } else {
          await panelsApi.openBlocksPanel('DesignPanel', componentRef, {
            overrides: {
              title: t('app.settings.gfpp.design'),
            },
          });
        }
        break;
      }
      case casePanel.MANAGE_PRESETS: {
        await editorSDK.editor.openPanel(
          'compPanels.panels.Widget.widgetPresetsPanel',
          {
            selectedComponent: [componentRef],
            frameType: 'compPanelFrame',
          },
          false,
        );
      }
    }
  });

  await editorSDK.addEventListener('componentGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    const refItem = await findCompRefButton(componentRef, editorSDK);

    switch (id) {
      case casePanel.MANAGE_TEXT: {
        const widgetProps =
          await editorSDK.application.appStudioWidgets.props.get('token', {
            widgetRef: refItem,
          });
        updateFileNameBeforeOpenNativeSettings(
          editorSDK,
          widgetProps,
          t,
          myButtonRef,
        );
        await editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
          'token',
          'settings',
          {
            componentRef: myButtonRef,
            configuration: {
              controls: {
                link: { hidden: true },
                label: {},
                icon: {},
              },
              controlGroups: {},
              states: {},
            },
          },
        );
        updateDataAfterCloseSettings(
          editorSDK,
          refItem,
          widgetProps,
          myButtonRef,
          myTextRef,
        );
      }
    }
  });
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  appManifestBuilder.configureWidget(
    '89ad9892-2932-455c-9828-10eb93407be9-lfyze',
    (widgetBuilder: WidgetBuilder) => {
      widgetBuilder.gfpp().set('mainAction1', {
        label: t('app.settings.upload.pdf'),
        actionId: casePanel.MANAGE_PDF,
      });
      widgetBuilder.gfpp().set('presets', {
        behavior: 'HIDE',
      });
      widgetBuilder.gfpp().set('mainAction2', {
        actionId: casePanel.MANAGE_PRESETS,
        label: t('app.presets.gfpp.label'),
      });

      widgetBuilder.gfpp().set('settings', {
        actionId: casePanel.MANAGE_SETTINGS,
      });
      widgetBuilder.gfpp().set('layout', {
        actionId: casePanel.MANAGE_LAYOUT,
      });
      widgetBuilder.gfpp().set('help', {
        id: 'ebfb6884-e5e2-4ff5-ab42-48a144612f23',
      });
      widgetBuilder.gfpp().set('design', {
        actionId: casePanel.MANAGE_DESIGN,
      });
      const mobileProperties: any = [
        'mainAction1',
        'mainAction2',
        'settings',
        'layout',
        'animation',
        'design',
        'presets',
      ];

      mobileProperties.forEach((property) => {
        if (property === 'design') {
          widgetBuilder
            .gfpp('mobile')
            .set(property, { actionId: 'ManageDesign' });
        } else {
          widgetBuilder.gfpp('mobile').set(property, { behavior: 'HIDE' });
        }
      });

      widgetBuilder.configureWidgetPresets(
        (widgetPresetsBuilder: WriteOnlyBuilder<WidgetPresetsBuilder>) => {
          const presetss: WidgetPreset[] = designPresetsFullPage(t);
          const basicSettings: any = {
            isDesignButtonVisible: false,
            isLayoutButtonVisible: false,
          };
          widgetPresetsBuilder.set(basicSettings);
          widgetPresetsBuilder.setPresets(presetss);
        },
      );

      widgetBuilder.configureConnectedComponents(
        'box1',
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder
            .behavior()
            .set({ closed: { selectable: true } });
        },
      );
    },
  );
  appManifestBuilder.configureWidget(
    '89ad9892-2932-455c-9828-10eb93407be9-zxjkk',
    (widgetBuilder: WidgetBuilder) => {
      widgetBuilder.configureConnectedComponents(
        'text1',
        (innerWidgetBuilder) => {
          innerWidgetBuilder.gfpp('mobile').set('mainAction2', {
            behavior: 'HIDE',
          });
          innerWidgetBuilder.gfpp().set('mainAction2', {
            label: t('app.settings.gfpp.text.edit.text'),
            actionId: casePanel.MANAGE_TEXT,
          });
        },
      );
      widgetBuilder.configureConnectedComponents(
        'button1',
        (innerWidgetBuilder) => {
          innerWidgetBuilder.gfpp('mobile').set('mainAction1', {
            label: t('app.settings.gfpp.design'),
            onClick: async (event: any) => {
              const { componentRef } = event.detail;
              await editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
                'token',
                'design',
                {
                  componentRef,
                },
              );
            },
          });
          innerWidgetBuilder.gfpp().set('settings', {
            onClick: async (event: any) => {
              const { componentRef } = event.detail;
              const refItem = await findCompRefButton(componentRef, editorSDK);
              const widgetProps =
                await editorSDK.application.appStudioWidgets.props.get(
                  'token',
                  {
                    widgetRef: refItem,
                  },
                );
              updateFileNameBeforeOpenNativeSettings(
                editorSDK,
                widgetProps,
                t,
                myButtonRef,
              );
              await editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
                'token',
                'settings',
                {
                  componentRef: myButtonRef,
                  configuration: {
                    controls: {
                      link: { hidden: true },
                      label: {},
                      icon: {},
                    },
                    controlGroups: {},
                    states: {},
                  },
                },
              );
              updateDataAfterCloseSettings(
                editorSDK,
                refItem,
                widgetProps,
                myButtonRef,
                myTextRef,
              );
            },
          });
          innerWidgetBuilder.gfpp().set('design', {
            onClick: async (event: any) => {
              const { componentRef } = event.detail;
              await editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
                'token',
                'design',
                {
                  componentRef,
                },
              );
            },
          });
          innerWidgetBuilder.gfpp().set('mainAction1', {
            label: t('app.settings.inner.layout.gfpp'),
            onClick: async (event: any) => {
              const { componentRef } = event.detail;
              await editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
                'token',
                'layout',
                {
                  componentRef,
                },
              );
            },
          });
        },
      );
    },
  );
  return appManifestBuilder.withJsonManifest(baseManifest).build();
};
const changeDesktopPreset = (presetId: string) => {
  const { embedMode, state, width, height }: any = desktopPresets.find(
    ({ id }) => presetId === id,
  );

  return { embedMode, state, width, height };
};
const changeMobilePreset = (presetId: string) => {
  const { embedMode, state, width, height }: any = mobilePresets.find(
    ({ id }) => presetId === id,
  );

  return { embedMode, state, width, height };
};

export const editorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: FlowAppDefinitionId,
  options: FlowPlatformOptions,
  flowAPI: FlowAPI,
) => {
  const logger = webBiLogger.factory().logger();
  const instanceId = await editorSDK.info.getAppInstanceId('');
  const siteId = await editorSDK.info.getSiteId('');
  const metaSiteId = await editorSDK.info.getMetaSiteId('');
  const appId = await editorSDK.info.getAppDefinitionId('');

  await editorSDK.addEventListener(
    'globalDesignPresetChanged',
    async (event) => {
      const { preset, componentRef } = event.detail;
      const newPreset = changeDesktopPreset(preset);
      const newPresetMobile = changeMobilePreset(preset);
      try {
        const [rootWidgetParentRef] =
          await editorSDK.document.components.getAncestors('', {
            componentRef,
          });
        const widgetProps =
          await editorSDK.application.appStudioWidgets.props.get('token', {
            widgetRef: componentRef,
          });
        await editorSDK.components.refComponents.removeAllOverrides('', {
          componentRef: rootWidgetParentRef,
        });
        await editorSDK.application.appStudioWidgets.props.set('token', {
          widgetRef: componentRef,
          newProps: {
            ...widgetProps,
            embedMode: newPreset.embedMode,
            variantId: preset,
          },
        });
        await editorSDK.application.appStudioWidgets.changePreset('', {
          context: { viewport: 'MOBILE' },
          componentRef: rootWidgetParentRef,
          stylePresetId: preset,
          layoutPresetId: preset,
        });
        await editorSDK.document.components.layout.update('', {
          componentRef: { ...rootWidgetParentRef, type: 'DESKTOP' },
          layout: {
            width: newPreset.width,
            height: newPreset.height,
          },
        });
        setTimeout(async () => {
          await editorSDK.document.components.layout.update('', {
            componentRef: { ...rootWidgetParentRef, type: 'MOBILE' },
            layout: {
              width: newPresetMobile.width,
              height: newPresetMobile.height,
            },
          });
        }, 1000);
        if (options.origin.type === 'RESPONSIVE') {
          const responsiveLayout: ResponsiveLayout =
            await editorSDK.document.responsiveLayout.get('token', {
              componentRef: { ...rootWidgetParentRef, type: 'DESKTOP' },
            });
          await editorSDK.document.responsiveLayout.update('token', {
            componentRef: { ...rootWidgetParentRef, type: 'DESKTOP' },
            responsiveLayout: {
              ...responsiveLayout,
              componentLayouts: [
                {
                  ...responsiveLayout.componentLayouts[0],
                  width:
                    newPreset.width === 980
                      ? { type: 'percentage', value: 70.3 }
                      : { type: 'auto' },
                  height:
                    newPreset.height === 550
                      ? { type: 'percentage', value: 83.6 }
                      : { type: 'auto' },
                },
              ],
            },
          });
        }
        await editorSDK.document.application.livePreview.refresh('', {
          shouldFetchData: true,
          source: '',
        });
      } catch (e) {}
    },
  );
  await editorSDK.addEventListener('siteWasPublished', async (event) => {
    const [allAppRefComponents] =
      await editorSDK.document.components.refComponents.getAllAppRefComponents(
        'token',
      );
    const [componentWidgetRef] = await editorSDK.components.getChildren('', {
      componentRef: allAppRefComponents,
    });
    const widgetProps = await editorSDK.application.appStudioWidgets.props.get(
      'token',
      {
        widgetRef: componentWidgetRef,
      },
    );
    const { embedMode, showPrint, showDownload, startPage, url }: any =
      widgetProps;
    const defaultPDFLink = findDefaultPDFLink(flowAPI);
    const checkURL = url === defaultPDFLink ? false : true;
    logger.report(
      pdfDetails({
        app_id: appId,
        app_site_id: siteId,
        event_trigger: 'publish',
        instance_id: instanceId,
        biToken: metaSiteId,
        site_id: instanceId,
        wixlabs_bool_1: checkURL,
        wixlabs_bool_2: showDownload,
        wixlabs_bool_3: showPrint,
        wixlabs_num_1: startPage,
        wixlabs_string_1: embedMode,
      }),
    );
  });

  panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType: options.origin.type,
    essentials: options.essentials,
  });

  const addWidget = async () => {
    return editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: '30fdf35a-e0cd-4a32-a573-8475973a95ff',
      installationType: WidgetInstallationType.Closed,
      scopedPresets: {
        desktop: {
          layout: desktopPresets[0].id,
          style: desktopPresets[0].id,
        },
        mobile: {
          layout: mobilePresets[0].id,
          style: mobilePresets[0].id,
        },
      },
      dimensionsByDevice: {
        desktop: {
          width: { type: 'px', value: desktopPresets[0].width },
          height: { type: 'px', value: desktopPresets[0].height },
        },
        mobile: {
          width: { type: 'px', value: mobilePresets[0].width },
          height: {
            type: 'px',
            value: mobilePresets[0].height,
          },
        },
      },
      layout: {
        height: 550,
        width: 980,
        x: 0,
        y: 100,
      },
      layouts: {
        componentLayout: {
          id: desktopPresets[0].id,
          hidden: false,
          height: {
            type: 'percentage',
            value: 83.6,
          },
          type: 'ComponentLayout',
          width: {
            type: 'percentage',
            value: 70.3,
          },
        },
        itemLayout: {
          id: desktopPresets[0].id,
          alignSelf: 'center',
          margins: {
            left: {
              type: 'px',
              value: 0,
            },
            right: {
              type: 'percentage',
              value: 0,
            },
            top: {
              type: 'px',
              value: 0,
            },
            bottom: {
              type: 'px',
              value: 0,
            },
          },
          gridArea: {
            rowStart: 1,
            rowEnd: 2,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'center',
          type: 'GridItemLayout',
        },
        containerLayout: {
          id: '',
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      },
    } as any);
  };
  if (options.firstInstall) {
    const widgetRef = await addWidget();
    if (widgetRef) {
      await updateUrlPDF(widgetRef, editorSDK, flowAPI);
    }
  }
};

export const exports = (editorSDK: FlowEditorSDK) => ({
  public: {
    async addWidget() {
      const api = await editorSDK.editor.getAppAPI();
      return api.addWidget();
    },
  },
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      return (
        await editorSDK.application.appStudioWidgets.getPreset('', {
          componentRef: widgetRef,
        })
      ).style;
    },
  },
});
